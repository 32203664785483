const userAuth = JSON.parse(localStorage.getItem('user'))

const config = {
    basename: '/admin',
    defaultPath: userAuth == null ? '/login/' : '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    theme: 'light',
    i18n: 'es',
};

export default config;
