import React, {lazy} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';

import MainLayout from './../layout/MainLayout';


const DashboardDefault = lazy(() => import('../views/dashboard/Default'));

const TableBasic = lazy(() => import('../views/forms/tables/TableBasic'));
const TableDense = lazy(() => import('../views/forms/tables/TableDense'));


const ListProjects = lazy(() => import('../views/projects/List_Projects'));
const AddProjetc= lazy(() => import('../views/projects/Add_Project'));
const UpdateProjetc= lazy(() => import('../views/projects/Update_Project'));

const ListLotes = lazy(() => import('../views/clients/List_Clients'));
const AddLote = lazy(() => import('../views/clients/Add_Client'));
const ContractGenerate = lazy(() => import('../views/sales/Contract_Generate'));

const ListAgentes = lazy(() => import('../views/agentes/List_Agentes'));
const AddAgente = lazy(() => import('../views/agentes/Add_Agente'));

const ListSales = lazy(() => import('../views/sales/List_Sales'));
const AddSales = lazy(() => import('../views/sales/Add_Sale'));
const Cobranza = lazy(() => import('../views/cobranza/cobranza'));
const ListPagos = lazy(() => import('../views/pagos/List_Pagos'));
const Cerrar = lazy(() => import('../views/logout/cerrarSession'));


const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',
                '/dashboard/cobranza',
                '/tables/tbl-basic',
                '/tables/tbl-dense',

                '/inventario/proyectos/lista',
                '/inventario/proyectos/agregar',

                '/inventario/clientes/lista',
                '/inventario/clientes/agregar',
                '/inventario/sales/contrato/:id',

                '/inventario/agentes/lista',
                '/inventario/agentes/agregar',
                '/sales/lista',
                '/sales/agregar',
                '/dashboard/pagos/lista',
                '/inventario/clientes/editar/:id',
                '/inventario/agentes/editar/:id',
                '/inventario/proyectos/editar/:id',
                '/salir'





            ]}
        >
            <MainLayout showBreadcrumb={true}>
                <Switch location={location} key={location.pathname}>
                        <Route path="/dashboard/default" component={DashboardDefault} />

                        <Route path="/tables/tbl-basic" component={TableBasic} />
                        <Route path="/tables/tbl-dense" component={TableDense} />

                      
                        <Route path="/inventario/proyectos/lista" component={ListProjects} />
                        <Route path="/inventario/proyectos/agregar" component={AddProjetc} />
                        <Route path="/inventario/proyectos/editar/:id" component={UpdateProjetc} />

                        <Route path="/inventario/clientes/lista" component={ListLotes} />
                        <Route path="/inventario/clientes/agregar" component={AddLote} />
                        <Route path="/inventario/sales/contrato/:id" component={ContractGenerate} />

                        <Route path="/inventario/clientes/editar/:id" component={AddLote} />


                        <Route path="/inventario/agentes/lista" component={ListAgentes} />
                        <Route path="/inventario/agentes/agregar" component={AddAgente} />
                        <Route path="/inventario/agentes/editar/:id" component={AddAgente} />


                        <Route path="/sales/lista" component={ListSales} />
                        <Route path="/sales/agregar" component={AddSales} />
                        <Route path="/dashboard/cobranza" component={Cobranza} />
                        <Route path="/dashboard/pagos/lista" component={ListPagos} />
                        <Route path="/salir" component={Cerrar} />


                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
