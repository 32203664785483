import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    IconCircle,
    IconClipboardList,
    IconDashboard,
    IconUser,
    IconBuildingStore,
    IconTruck,
    IconReportMoney,
    IconBusinessplan,
    IconCoin,
    IconReportAnalytics,
    IconReceipt
} from '@tabler/icons';

const icons = {
    IconDashboard: IconDashboard,
    IconClipboardList: IconClipboardList,
    IconCircle: IconCircle,
    IconUser: IconUser,
    IconBuildingStore: IconBuildingStore,
    IconTruck: IconTruck,
    IconReportMoney: IconReportMoney,
    IconBusinessplan: IconBusinessplan,
    IconCoin: IconCoin,
    IconReportAnalytics: IconReportAnalytics,
    IconReceipt:IconReceipt
};

const menuItems = {
    items: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'group',
            children: [
                {
                    id: 'dash-default',
                    title: <FormattedMessage id="default" />,
                    type: 'item',
                    url: '/dashboard/default',
                    icon: icons['IconDashboard'],
                },
                {
                    id: 'cobranza',
                    title: <FormattedMessage id="cobranza" />,
                    type: 'item',
                    url: '/dashboard/cobranza',
                    icon: icons['IconBusinessplan'],
                },
                {
                    id: 'abonos',
                    title: <FormattedMessage id="abonos" />,
                    type: 'item',
                    url: '/dashboard/pagos/lista',
                    icon: icons['IconReceipt'],
                }
            ]
        },

        {
            id: 'inventario',
            title: <FormattedMessage id="inventario" />,
            type: 'group',
            children: [
                {
                    id: 'venta',
                    title: <FormattedMessage id="venta" />,
                    type: 'collapse',
                    icon: icons['IconReportMoney'],
                    children: [
                        {
                            id: 'nueva_venta',
                            title: <FormattedMessage id="nueva_venta" />,
                            type: 'item',
                            url: '/sales/agregar',
                            icon: icons['IconCoin'],
                        },
                        {
                            id: 'lista_ventas',
                            title: <FormattedMessage id="lista_ventas" />,
                            type: 'item',
                            url: '/sales/lista',
                            icon: icons['IconReportAnalytics'],
                        }
                    ]
                },
               

                {
                    id: 'proyectos',
                    title: <FormattedMessage id="proyectos" />,
                    type: 'collapse',
                    icon: icons['IconBuildingStore'],
                    children: [
                        {
                            id: 'nuevo_proyecto',
                            title: <FormattedMessage id="nuevo_proyecto" />,
                            type: 'item',
                            url: '/inventario/proyectos/agregar',
                        },
                        {
                            id: 'lista_proyectos',
                            title: <FormattedMessage id="lista_proyectos" />,
                            type: 'item',
                            url: '/inventario/proyectos/lista',
                        },


                    ]
                },

                {
                    id: 'clientes',
                    title: <FormattedMessage id="clientes" />,
                    type: 'collapse',
                    icon: icons['IconClipboardList'],
                    children: [
                        {
                            id: 'nuevo_cliente',
                            title: <FormattedMessage id="nuevo_cliente" />,
                            type: 'item',
                            url: '/inventario/clientes/agregar',
                        },
                        {
                            id: 'lista_clientes',
                            title: <FormattedMessage id="lista_clientes" />,
                            type: 'item',
                            url: '/inventario/clientes/lista',
                        },


                    ]
                },
                {
                    id: 'agentes',
                    title: <FormattedMessage id="agentes" />,
                    type: 'collapse',
                    icon: icons['IconUser'],
                    children: [
                        {
                            id: 'nuevo_agente',
                            title: <FormattedMessage id="nuevo_agente" />,
                            type: 'item',
                            url: '/inventario/agentes/agregar',
                        }
                        ,
                        {
                            id: 'lista_agentes',
                            title: <FormattedMessage id="lista_agentes" />,
                            type: 'item',
                            url: '/inventario/agentes/lista',
                        },

                    ]
                },

            ]
        },
        {
            id: 'salir',
            title: <FormattedMessage id="salir" />,
            type: 'group',
            children: [
                {
                    id: 'salir',
                    title: <FormattedMessage id="salir" />,
                    type: 'item',
                    url: '/salir',
                    icon: icons['IconDashboard'],
                },
             
            ]
        },


    ]
};

export default menuItems;
